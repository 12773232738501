import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../../components/hero"

// Page Sections
import List from "../screens/resources/publications/list"

const Publications = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Publications">
        <p>Research papers from accredited journals on the disruptive Visby&nbsp;Medical breakthrough.</p>
      </Hero>
      <List />
    </React.Fragment>
  )
}

export default Publications


export const Head = () => (
  <Seo
        title="Publications"
        description="Research papers from accredited journals on the disruptive Visby Medical breakthrough."
        image="/meta/insights-publications.jpg"
  />
)
